import './App.css';
import { useId, useState } from 'react';
import { useEffect } from 'react';
import React from 'react';

function App(props) {
  const id = useId();
  const id2 = useId();
  const id3 = useId();
  const [input, setInput] = useState(props?.input ?? '科技行腳');
  //let [input, setInput] = useState(props?.name ?? '');

  let [trigger, setTrigger] = useState(false);
  const [data,setData]=useState([]);
  const [check,setCheck]=useState({});
  let ic975_api = 'https://mindjoy.npkn.net/flask-ic975/api';
  const base_url = "https://www.ic975.com/program/";
  let ic975_url = base_url + input;//+ "科技行腳";
  //let pages = 1;
  const [pages, setPages] = useState(1);

  let my_url = ic975_api+'?url='+ic975_url+'&pages='+pages;
  let [go, setGo] = useState(false);
  function run_fetch() {
    //alert('yes?');
    //setTrigger(true);
    setTrigger(!trigger);
    setGo(true);
    //console.log(trigger);
  };

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(
        my_url, //`https://hn.algolia.com/api/v1/search?query={query}`,
      );
      const json = await res.json();
      setData(json.slice(1));
      //console.log(json);
      setCheck(json[0]);
    };

    if (go) {fetchData()};

  }, [trigger]);
if(check.status !== 'ok') {
  return (
    <div className="App">
      {/* <h1>input from outer: {props.input}</h1> */}
      <h1>ic975 podcast</h1>
      <label htmlFor={id}>Program:</label>
      <input id={id} value={input} onInput={e => setInput(e.target.value)}/>

      <label htmlFor={id3}>Built-in:</label>
      <select value={input} onInput={e => setInput(e.target.value)}>
	      <option value="科技行腳">科技行腳</option>
	      <option value="科技聽IC">科技聽IC</option>
	      <option value="晚安．月亮─床邊故事">晚安．月亮─床邊故事</option>
      </select>

      <label htmlFor={id2}>Pages:</label>
      <input id={id2} value={pages} onInput={e => setPages(e.target.value)}/>
      <button onClick={run_fetch}>Run</button>
    </div>
  );  
}
  return (
    // <React.Fragment>
    <div className="App">
      {/* <h1>input from outer: {props.name}</h1> */}
      <h1>ic975 podcast</h1>
      <label htmlFor={id}>Program:</label>
      <input id={id} value={input} onInput={e => setInput(e.target.value)}/>

      <label htmlFor={id3}>Built-in:</label>
      <select value={input} onInput={e => setInput(e.target.value)}>
	      <option value="科技行腳">科技行腳</option>
	      <option value="科技聽IC">科技聽IC</option>
	      <option value="晚安．月亮─床邊故事">晚安．月亮─床邊故事</option>
      </select>

      <label htmlFor={id2}>Pages:</label>
      <input id={id2} value={pages} onInput={e => setPages(e.target.value)}/>
      <button onClick={run_fetch}>Run</button>
      <h2>{check.name}</h2>
      <ul>
        {data.map(item => (
          <li key={item.url}>
            <a href={item.url}>{item.title}</a>
          </li>
        ))}
      </ul>
    </div>
    // </React.Fragment>
  );
}

export default App;
